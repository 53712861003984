<template>
  <div class="property-wrapper">
    <template v-if="controls.length == 1">
      <ControlBaseProperties
        v-model="control"
        @setExpression="buildExpression($event)"
        :hasExpression="expression != ''"
        :allowedTypes="['bool', 'float', 'int', 'string']"
      />
      <ControlSpecificPanel :control="control">
        <div class="form-group form-group-sm" v-if="dataTemplate">
          <label>{{ $t("expression") }}</label>
          <JSONPathPicker
            entryType=""
            :append="true"
            :curlyBracked="true"
            :entry="dataTemplate"
            v-model="expression"
          />
        </div>
        <DataFormatInput v-model="control.format" :control="control" />
      </ControlSpecificPanel>
    </template>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { isEqual } from "lodash";
import ControlBaseProperties from "./control-base-properties";
import BaseControl from "./base-control.vue";
import ControlSpecificPanel from "@/components/editor/control-specific-panel.vue";
import DataFormatInput from "@/components/synoptic/property-editor/controls/data-format-input.vue";
import JSONPathPicker from "@/components/control-sidebar/property-editors/json-path-picker.vue";
import SelectableObjects from "@/assets/dashboard/selectable_objects.json";
export default {
  name: "FormattedDisplayValue",
  components: {
    ControlBaseProperties,
    ControlSpecificPanel,
    DataFormatInput,
    JSONPathPicker
  },
  extends: BaseControl,
  data() {
    return {
      control: {}
    };
  },
  computed: {
    expression: {
      set(value) {
        if (this?.control?.synopticComponent) {
          this.$set(this.control.synopticComponent, "expression", value);
        }
      },
      get() {
        return this?.control?.synopticComponent?.expression || "";
      }
    },
    equipmentData() {
      return this?.control?.data_id
        ? (this.$store.getters["dashboard/dataList"] || []).find(
            ({ id }) => id == this?.control?.data_id
          )
        : null;
    },
    dataTemplate() {
      let entry = { system: this.$store.getters.systemProperties };
      if (this?.control?.data_id) {
        var data = JSON.parse(JSON.stringify(SelectableObjects.data));
        if (this.equipmentData) {
          this.$utils.copyIfExists(
            data,
            JSON.parse(JSON.stringify(this.equipmentData))
          );
        }
        entry = { ...entry, ...{ data: data } };
      }
      return entry;
    }
  },
  watch: {
    control: {
      deep: true,
      handler(val) {
        this.updateControl({
          id: val.id,
          control: this.control,
          noMerge: true
        });
      }
    },
    controls: {
      deep: true,
      handler(val) {
        let n = JSON.parse(JSON.stringify(val[0]));
        let o = JSON.parse(JSON.stringify(this.control));
        if (!isEqual(n, o)) {
          this.control = JSON.parse(JSON.stringify(n));
        }
      }
    }
  },
  methods: {
    buildExpression(source) {
      this.control.format = "";
      this.expression = source.replace(/\$\('[\d]+/, "$('.");
      this.$utils.notifyUser(this, this.$t("titles.expression_created"));
    },
    ...mapActions("synoptic", ["updateControl"])
  },
  mounted() {
    this.control = JSON.parse(JSON.stringify(this.controls[0]));
  }
};
</script>
